<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar
        class="pc-topbar"
        :class="{ 'letter-ios-14': $store.state.deviceType == 1 }"
        :bigtitle="true"
        :title="$store.state.isPC ? 'Frequently asked questions' : 'FAQs'"
        :showBack="true"
      ></topbar>
      <div class="pc-box big pc-box2">
        <div class="pc-min-content-pct">
          <div class="title-PC2" v-if="$store.state.isPC">Frequently asked questions</div>
          <div class="content" style="padding: 0px 4px 20px">
            <van-collapse v-model="activeNames">
              <van-collapse-item class="faq-cell" v-for="(i, k) in $store.state.moreInfo.faqsData" :key="k" title-class="font-18 font-b main-color" :title="i.title" :name="k">
                <p class="font-16-24 color-66" v-html="i.content"></p>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "faqs",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar },
  data() {
    return {
      activeNames: ["1"],
      faqsData: [],
    };
  },
  methods: {
    backEvent() {
      this.$router.push({
        path: "/main/more",
      });
    },
  },
  ionViewWillEnter() {
    this.$store.state.exitApp = false;
    this.sendGAPageView("FAQs");
  },
};
</script>
